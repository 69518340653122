<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle text="IBIE国际测评与认证" />
      <div class="ibie">
          <h1>一．IBIE国际测评与认证</h1>
          <div style="margin-left:30px">
            I----以多元文化为背景 <br/>
            B----以国际商务为准绳 <br/>
            I----以公民形象为出发 <br/>
            E----以世界礼仪为共赢 <br/>
          </div>
          <p>IBIE国际形象礼仪组织协会—International Business Image Etiquette Association是由中国形象和礼仪专业领域相关专家及老师、美国加州大学洛杉矶分校社科院专家学者、美国加州州立理工大学波莫纳分校及社会各界对该领域抱有兴趣的有识之士共同创建的NPO性质组织协会。该协会会员分布世界各地，始终保持着对形象礼仪的热爱，秉承着公平、公正、创美、和谐的理念和宗旨，是世界范围内新兴崛起的形象礼仪专业人员顶尖的国际形象礼仪组织。为大家提供全方位的资源对接和技术交流与合作。</p>
          <p>之所以要建立这样的非盈利的专业认证组织, 乃是由于中国国内形象礼仪行业与国际的差距还是十分巨大。在中国国家实力快速提升和充实的当下，如何快速将国内大众的形象礼仪知识和意识提升到与国力相符，是目前的当务之急。并且，如何深挖和充实我国历史上辉煌的形象礼仪文化，并充实国人和传播到国外，弘扬中华民族的璀璨文明，也是协会发展的未来规划。</p>
          <p>专业是对学术的敬畏，无知是对专业的无畏。</p>
          <p>IBIE国际形象礼仪组织协会吸收好人品、仁爱人、懂敬业、知感恩，敬畏、敬爱、敬礼的会员，如果您想加入一个和谐、进取、创新、共享、共赢、人脉，不分肤色、不分国界、不分jet lag的团体，有大爱、有关怀、有智慧、有想法的你考虑一下加入我们吧！</p>
          <p>为表入会诚意，采取年会费制度，200¥/年/人（主要用途是协会运作及沙龙等活动。我们的活动涉及奥运会、商会、高峰论坛、医院、公益、慈善、志愿者等。而且群内会定期举办礼仪讲座，分享经验及课件）入籍及弃籍一切采取自主自愿。</p>
          <p>入会资格：</p>
          <p>1. 无论你来自哪个国家，需年满18周岁，工作三年以上，档案无不良记录。</p>
          <p>2. 语言能力，汉语/英语/西班牙语/葡萄牙语/意大利语/日语/韩语，小语种亦可。任意一种。</p>
          <p>3. 国家认可大专以上学历。College degree or above.</p>
          <p>4. 三观正确，生活态度积极。</p>
          <p>请扫描下面二维码立即支付年会费成为会员。我们期待与您携手同行！</p>
          <div class="imgs">
            <img src="../../assets/img/IBIE/1.png" alt="">
            <img src="../../assets/img/IBIE/2.png" alt="">
            <img src="../../assets/img/IBIE/3.png" alt="">
            <img src="../../assets/img/IBIE/4.png" alt="">
          </div>

          <h1>二．名师授课----创始人介绍</h1>
          <h1>逄天舒Angelina 国际形象礼仪专家/文化部形象设计师</h1>

          <div>
            国际形象礼仪标准概念发起人 <br/>
            国家文化部认证高级形象设计师 <br/>
            美国加州波莫纳大学受邀访问学者 <br/>
            美国加州州立理工大学POMONA工商管理硕士 <br/>
            IBIE国际形象礼仪组织协会全球理事长 <br/>
            AICI IMMIE 奖国内大陆唯一获奖者 <br/>
            美国加州大学UCLA特约礼仪师 <br/>
            中国国际形象礼仪文化研究院院长 <br/>
            北京国际形象礼仪商学院院长 <br/>
            北京瑜璟缘国际文化发展有限公司CEO <br/>
            共青团北京市委员会礼仪讲师（公益） <br/>
            中国航空运输CATA协会航空乘务教员 <br/>
            英孚教育特约合作双语商务礼仪讲师 <br/>
            2022北京冬奥组委特约咨询礼仪师  <br/>
            2008奥运会中国国际航空奥运礼仪天使 <br/>
            2014全国高等院校空乘推介展示会礼仪导师 <br/>
            2015北京首航知音乘务招聘会评委 <br/>
            北京对外经济贸易大学客座讲师 <br/>
            北京邮电大学客座讲师 <br/>
            辽宁电视台金话筒教育特邀礼仪专家 <br/>
            北京旅游卫视我爱畅游栏目特约礼仪讲师 <br/>
            中国国际航空公司、海南航空、南方航空空乘形体礼仪培训专家
          </div>

          <p>12年服务行业与管理实战背景</p>
          <p>曾任：沈阳航空航天大学航空服务专业空乘培训导师，并具备近十年的航空飞行经历，累计飞行超过一万小时。为国内多所航空专业院校培训。2016年作为航空业界人才交流赴美提供技术交流与合作。</p>
          <p style="text-indent:2em">连续2年受聘于中国国际航空公司及北京首航知音商务服务有限公司、北京首都机场要客服务部。从事空乘人员及要客服务人员的职业化形象、仪态礼仪、服务礼仪、乘客沟通礼仪等专业应用礼仪培训，学员输送至国内不同航空。</p>
          <p style="text-indent:2em">从事礼仪培训讲师及形象管理师多年，擅长针对行业定制礼仪课程，如IT行业、金融行业、服务行业；公务员、医护人员、教师及企业中高层管理者。到目前为止，已为包括航空公司、酒店、医院、学校、银行和行政机关、事业单位、国有企业、民营企业、上市公司等提供专业的服务礼仪培训。</p>
          <p>主讲课程：</p>
          <p><b>行业定制礼仪课程：</b></p>
          <div>
            1.《国际商务礼仪与形象塑造》  <br/>
            2.《礼仪文化应用之社交宴请》  <br/>
            3.《国际形象礼仪》 <br/>
            4.《涉外礼仪修养与国际交流》 <br/>
            5.《国际西餐礼仪与修养》  <br/>
            6.《密歇根——斯坦戈尔摩绅士精英养成记》 <br/>
            7.《教师优雅职业礼仪》 <br/>
            8.《高端客户维护与处理投诉技巧》 <br/>
            9. 《乘务员面试技巧》 <br/>
            10. 《民航服务英语》 <br/>
            11. 《乘务员职业礼仪》 <br/>
            12. 《乘务序列素质模型》  <br/>
            13. 《乘务员面试化妆技巧》 <br/>
            14. 《中国民用航空乘务员礼仪规范》 <br/>
            15. 《中国民航国际航线服务差异》 <br/>
            16. 《民航投诉及处理投诉技巧》
          </div>
          <p><b>形象管理定制课程：</b></p>
          <div>
            1. 《形象管理—色彩搭配及应用》 <br/>
            2. 《形象管理—360度职场魅力女性着装提升五要素》  <br/>
            3. 《形象管理—高端商务男士形象规划》  <br/>
            4. 《形象管理—男装品质衣橱规划》  <br/>
            5. 《形象管理—国际礼仪讲师魅力着装规范》  <br/>
            6. 《形象管理—TPOR`场合装扮》  <br/>
            7. 《形象管理—人体基因六大风格（女士）/（男士）》
          </div>

           <p><b>常用礼仪：</b></p>
           <div>
             1.《国际商务礼仪》 <br/>
              2.《人际交往与沟通礼仪》 <br/>
              3.《优质服务礼仪》 <br/>
              4.《国际形象礼仪--世界公民形象塑造》 <br/>
              5.《国际形象礼仪》
           </div>
           <p><b>授课特点：</b></p>
           <p>1、<b>课程内容配比：</b>20%理论讲述+40%实际操作+30%情境演练，真正使培训内容落地，参训学员当场学到+做到，培训收效显著。</p>
           <p>2、<b>课程均为量身定制</b>，针对每家企业的现有问题展开，针对性极强。</p>

           <p><b>授课风格：</b></p>
            <p>天舒老师游历多个国家，接触各届名流、商务人士，不断与中外礼仪培训师、形象管理专家探讨、研究国际文化，积累了丰富而又实用的培训经验。2015年受美国加州州立理工大学邀请作为访问学者交流学习, 获美国加州Pomona理工大学CPELI语言学院研修证书，英语口语流利，同时也是一名对外汉语教师。授课风格<b>亲切自然、幽默理性、端庄严谨</b>，将理论与事实案例结合在一起，更适用于实际工作和生活中。同时课程含有丰富的文化底蕴，通过故事、游戏等方法诠释，具有很强的趣味性。 注重学员需求、尊重学员感受，特别注重课程内容的企业可操作性和个人可应用性。</p>
            <p>2012年创办北京高端女装集合品牌SUE STORY，2014年成立北京SUE形象商务形象美学俱乐部。2017年末将国际形象礼仪概念引入中国, 成立IBIE国际形象礼仪协会，为众多500强企业授课普及IBIE国际商务形象礼仪标准与测评。天舒老师兴趣爱好广泛，钢琴八级，热爱文艺，所授课程干货满满</p>
           
            <p><b>部分服务客户：</b></p>
            <p>中央电视台生活体育频道，中国国际航空客舱服务部，中建三局，东软集团，中国铝业，京博集团，酒仙网，华为涉外服务协会，万达集团，万鑫集团，农业银行，英孚教育，新绿轴硅谷国际学校，对外经贸大学，北京邮电大学，中国传媒大学，沈阳航空航天大学等。 </p>
            
            <p><b>部分授课照片：</b></p>

            <div class="imgs">
                <img src="../../assets/img/IBIE/5.png" alt="">
                <img src="../../assets/img/IBIE/6.png" alt="">
                <img src="../../assets/img/IBIE/7.png" alt="">
                <img src="../../assets/img/IBIE/8.png" alt="">
                <img src="../../assets/img/IBIE/9.png" alt="">
                <img src="../../assets/img/IBIE/10.png" alt="">
                <img src="../../assets/img/IBIE/11.png" alt="">
                <p><b>英孚教育：</b></p>
                <img src="../../assets/img/IBIE/12.png" alt="">
                <img src="../../assets/img/IBIE/13.png" alt="">

            </div>
      </div>
    </div>
    <sidebar />
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
export default {
  components: {
    textTitle,
    sidebar,
  },
};
</script>
<style lang="scss" scoped>
.ibie {
  margin: 0px 20px;
  font-size: 14px;
  line-height: 28px;
  h1{
    color: red;
    font-size: 18px;
    margin-bottom: 10px;
  }
  p,div{
    margin-top: 10px;
  }
  .imgs{
    text-align: center;
    img{
      width: 600px;
    }
  }
}
</style>